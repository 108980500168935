import * as React from "react";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { getParameterByName } from "../../lib/helpers";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#ffffff",
      background: "#6464F6",
      borderRadius: "2px",
      '&:hover': {
        background: "#6464F6 !important",
      }
    },
    "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)":
      {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "16px",
      color: "#3C465F",
        borderRadius: "2px",
      border: "0.5px solid #6464F6",
      },
    "& .MuiPaginationItem-icon": {
      backgroundColor: "yellow",
      "& > svg": {
        display: "none",
      },
    },
    "& ul > li:first-child > button:not(.Mui-selected)": {
      "&:after": {
        fontweight: 600,
        fontsize: "12px",
        lineheight: "16px",
        color: "#3C465F",
        content: "'Previous'",
      },
      "& > svg": {
        display: "none",
      },
    },
    "& ul > li:last-child > button:not(.Mui-selected)": {
      "&:after": {
        fontweight: 600,
        fontsize: "12px",
        lineheight: "16px",
        color: "#3C465F",
        content: "'Next'",
      },
      "& > svg": {
        display: "none",
      },
    },
  },
  paginationContainer: {
    paddingTop: "28px",
    display: "flex",
    justifyContent: "center",
  },
  placeholderContainer: {
    marginTop: "28px",
  },
}));

type ForumPaginationProps ={
  pagination:{
    currentPage: number;
    first: PageProps;
    last: PageProps;
    next: PageProps;
    prev: PageProps;
    pageCount: number;
    pages: {PageProps}[]
    rel:  {
      href: string;
      next: string;
    }[]
  }
  handleChange: (event: React.ChangeEvent, value: number) => void;
}

type PageProps = {
  page: number;
  active: boolean;
  qs: string;
}
const ForumPagination = ({pagination, handleChange}: ForumPaginationProps) => {
  const classes = useStyles();

  var defPage = 1;

  if (typeof window !== 'undefined') {
    var value = getParameterByName('page')
    if (value){
      defPage = parseInt(value);
     // console.log(value)
    }
  }


  if (pagination && pagination.pageCount > 1) {
    return (
      <div className={classes.paginationContainer}>
        <Pagination
          className={classes.root}
          count={pagination && pagination.pageCount}
          onChange={handleChange}
          defaultPage={defPage}
        />
      </div>
    );
  }
  return (
    <div className={classes.placeholderContainer}></div>
  )
};

export default ForumPagination;
