import { $getRoot } from "lexical";

function serializeToHtml(editorState) {

  const renderText = (node) => {
    switch (node.getFormat()) {
      case 1: // bold
        return `<strong>${node.getTextContent()}</strong>`;
      case 1 << 1: // italic
        return `<em>${node.getTextContent()}</em>`;
      case 1 << 2: // strikethrough
        return `<s>${node.getTextContent()}</s>`;
      case 1 << 3: // underline
        return `<u>${node.getTextContent()}</u>`;
      case 1 << 4: // code
        return `<code>${node.getTextContent()}</code>`;
      case 1 << 5: // subscript
        return `<sub>${node.getTextContent()}</sub>`;
      case 1 << 6: // superscript
        return `<sup>${node.getTextContent()}</sup>`;
      default:
        return node.getTextContent();
    }
  };

  const renderStyle = (format) => {
    switch (format) {
      case 1: // left
        return `text-align: left;`;
      case 2: // center
        return `text-align: center;`;
      case 3: // right
        return `text-align: right;`;
      case 4: // justify
        return `text-align: justify;`;
      default: // justify
        //console.log("unknown text-align", format);
        return ``;
    }
  };

  const renderNode = (node) => {
    switch (node.getType()) {
      case "root":
        return (node).getChildren().map((k) => renderNode(k)).join("");
      case "heading":
        const headingNode = (node);
        return `<${headingNode.getTag()}>${headingNode.getChildren()
          .map((k) => renderNode(k))
          .join("")}</${headingNode.getTag()}>`;
      case "list":
        const listNode = (node)
        return `<${listNode.getTag()}>${listNode.getChildren()
          .map((k) => renderNode(k))
          .join("")}</${listNode.getTag()}>`;
      case "text":
        return renderText(node);
      case "quote":
        const quoteNode = (node);
        return `<blockquote>${quoteNode.getChildren()
          .map((k) => renderNode(k))
          .join("")}</blockquote>`;
      case "paragraph":
        const paragraphNode = (node);
        return `<p${
          paragraphNode.getFormat() ? ` style="${renderStyle(paragraphNode.getFormat())}"` : ``
        }>${paragraphNode.getChildren().map((k) => renderNode(k)).join("")}</p>`;
      case "listitem":
        const listItemNode = (node)
        return `<li>${listItemNode.getChildren()
          .map((k) => renderNode(k))
          .join("")}</li>`;
      case "link":
        const linkNode = (node)
        return `<a href="${linkNode.getURL()}">${linkNode.getChildren()
          .map((k) => renderNode(k))
          .join("")}</a>`;
      default:
        //console.log("unknown type", node.getType());
        return "";
    }
  };

  return new Promise(resolve => {
    editorState.read(() => {
      resolve(renderNode($getRoot()));
    });
  });
}

export default serializeToHtml
